import React from 'react';
import Img from "gatsby-image";
import { graphql } from 'gatsby';
import { Carousel } from 'react-responsive-carousel';
import Layout from "../components/layout";

const ProjectDetails = ({data}) => {
    const projectData = data.projectJson;
    const projectImage = data.projectJson.features;
    // console.log(projectData.partners_logo);
    return (
        <Layout>
            <div className="rn-project-details-area rn-section-gapBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <div className="page-top">
                                    <h1 className="title_holder">{projectData.title}</h1>
                                </div>
                                <div className="portfolio-content mt--90 mt_lg--30 mt_md--20 mt_sm--20">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 col-12">
                                            <div className="content-left">
                                                <h3>Detalhes</h3>
                                                <ul className="list_holder">
                                                    <li>
                                                        <span className="icon">
                                                            <img className="img" src="https://sape.s3.amazonaws.com/imgs/icon+TIPO.png" alt=""/>
                                                            Tipo:
                                                        </span>
                                                        <span className="projectinfo">{projectData.category}</span>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <img className="img" src="https://sape.s3.amazonaws.com/imgs/icon+DIMENSAO.png" alt=""/>
                                                            Metragem:
                                                        </span>
                                                        <span className="projectinfo">{projectData.size}</span>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <img className="img" src="https://sape.s3.amazonaws.com/imgs/icon+LOCALIZAC%CC%A7A%CC%83O.png" alt=""/>
                                                            Localização:
                                                        </span>
                                                        <span className="projectinfo">{projectData.location}</span>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <img className="img" src="https://sape.s3.amazonaws.com/imgs/icon+PROJETO.png" alt=""/>
                                                            Projeto:
                                                        </span>
                                                        <span className="projectinfo">{projectData.project}</span>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <img className="img" src="https://sape.s3.amazonaws.com/imgs/icon+STATUS.png" alt=""/>
                                                            Status:
                                                        </span>
                                                        <span className="projectinfo">{projectData.status}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            {
                                                projectData.responsibles ?
                                                <div>
                                                    <p className='title'>Responsáveis</p>
                                                    {projectData.responsibles.map((data, index) => (
                                                        <p key={index}><span className="label">{data.label}</span> {data.value}</p>
                                                    ))}
                                                </div>
                                                : null
                                            }
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-12 mt_md--30 mt_sm--30">
                                            <div className="content-left">
                                                {projectData.body.map((data, index) => (
                                                    <p key={index} style={{textAlign:'justify'}}><span style={{display:'inline-block',width:20}}></span>{data}</p>
                                                ))}
                                            </div>
                                            <div className="partners-logo-group">
                                                {projectData.partners_logo ?
                                                    projectData.partners_logo.map((data, index) => (
                                                        <img key={index} alt="" src={data.image} className="partners-logo"/>
                                                    ))
                                                    : null
                                                }
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className="image-group image-group-desktop desktop-only">
                                    <Carousel showThumbs={false} showStatus={false} infiniteLoop preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50}>
                                        {projectImage.map((data, index) => (
                                            <div className="single-image" key={index}>
                                                <Img fluid={data.image?.childImageSharp.fluid} style={{height: "630px", width: "945px"}}/>
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                                <div className="image-group mobile-only">
                                    <Carousel showThumbs={false} showStatus={false} infiniteLoop preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50}>
                                        {projectImage.map((data, index) => (
                                            <div className="single-image" key={index}>
                                                <Img fluid={data.image?.childImageSharp.fluid}/>
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($id: String!) {
        projectJson(id: {eq: $id}) {
            id
            title
            body
            category
            size
            location
            project
            status
            responsibles {
                label
                value
            }
            partners_logo {
                image 
            }
            featured_image {
                childImageSharp {
                    fluid(maxHeight: 1000, maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationHeight
                        presentationWidth
                    }
                }
            },
            features {
                image {
                    childImageSharp {
                      fluid(maxWidth: 1920, maxHeight: 1280, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                        presentationHeight
                      }
                    }
                }
            }
            
        }
    }
`;
export default ProjectDetails;